// import logo from './logo.svg';
import './App.css';

function App() {

  const words = [
    "Wo kommst du eigentlich her?",
    "Sie haben einen Termin?",
    "Achso oder was?!",
    "Jedes Wort ist ein anderes Wort!",
    "Toll Jan! Toll!",
    "Was ist eigentlich mit dieser Orange?",
  ]

  let word = words[Math.floor(Math.random()*words.length)]

  return (
    <div className="app">
      <div className="app-header">
        <h1>Supernina!</h1>
        <div>{word}</div>
      </div>
    </div>
  );
}

export default App;
